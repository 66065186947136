export default {
  /// [SIGN UP]
  name: [
    (v) => !!v || '이름을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/.test(v) || '이름은 한글,영문,숫자만 입력 가능합니다.'
  ],
  email: [
    (v) => !!v || '이메일을 입력해주세요',
    (v) => /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || '이메일 형식이 맞지 않습니다'
  ],
  username: [
    (v) => !!v || '아이디를 입력해주세요',
    (v) =>
      (v.length >= 4 && v.length <= 20) ||
      '아이디는 최소 4자, 최대 20자입니다.',
    (v) => /^[A-Za-z0-9+]*$/.test(v) || '아이디는 영문, 숫자만 허용합니다',
  ],
  password: [
    (v) => !!v || '비밀번호를 입력해주세요',
    (v) => /(?:(?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(v) || '영문+숫자+특수문자+대문자 조합 8자 이상 입력해주세요',
  ],
  rePassword: (password) =>
    [
      (v) => !!v || '비밀번호 재확인을 입력해주세요',
      (v) => v.length > 6 || '재확인 비밀번호 길이는 6자 이상입니다',
      (v) => v == password || '비밀번호가 일치하지 않습니다',
    ],
  phone: [
    (v) => !!v || '휴대폰 번호를 입력해주세요',
    (v) => /^010-?([0-9]{4})-?([0-9]{4})$/.test(v) || '올바른 휴대폰 번호가 아닙니다'
  ],
  nickname: [
    (v) => !!v || '닉네임을 입력해주세요',
    (v) =>
      (2 <= v.length && v.length <= 8) || '최소 2글자 최대 8글자입니다',
    (v) =>
      !/[^(a-zA-Z0-9)]/gi.test(v) ||
      '닉네임은 영어, 숫자만 가능합니다',
  ],
  birthday: [
    (v) => !!v || '생년월일을 입력해주세요',
    (v) => /^\d{4}.(0[1-9]|1[0-2]).(0[1-9]|[12][0-9]|3[01])$/.test(v) || '생년월일은 YYYY.MM.DD 형식입니다'
  ],
  emailId: [
    (v) => !!v || '이메일을 입력해주세요',
    (v) =>
      !/[^(a-zA-Z0-9._\-)]/gi.test(v) ||
      '이메일 형식이 맞지 않습니다',
  ],
  emailAddress: [
    (v) => !!v || '도메인을 입력해주세요',
    (v) => /^((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(v) || '도메인 형식이 맞지 않습니다'
  ],

  /// [ENTERING]
  companyName: [
    (v) => !!v || '업체명을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s|]+$/.test(v) || '이름은 한글,영문,숫자만 입력 가능합니다',
    (v) => (v.length <= 50) || '최대 50글자까지 입력 가능합니다',
  ],
  registrationNumber: [
    (v) => !!v || '사업자등록번호를 입력해주세요',
    (v) => /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/.test(v) || '사업자등록번호 형식이 맞지 않습니다'
  ],
  taxType: [
    (v) => !!v || '과세구분을 선택해주세요',
  ],
  companyType: [
    (v) => !!v || '업태명을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|\s|]+$/.test(v) || '업태명은 한글만 입력 가능합니다',
    (v) => (v.length <= 50) || '최대 50글자까지 입력 가능합니다',
  ],
  companyItem: [
    (v) => !!v || '종목명을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|\s|]+$/.test(v) || '종목명은 한글만 입력 가능합니다',
    (v) => (v.length <= 50) || '최대 50글자까지 입력 가능합니다',
  ],
  ownerName: [
    (v) => !!v || '대표자명을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|]+$/.test(v) || '대표자명은 한글만 입력 가능합니다',
    (v) => (v.length <= 50) || '최대 50글자까지 입력 가능합니다',
  ],
  ownerIdentityNumber: [
    (v) => !!v || '주민,법인등록번호를 입력해주세요',
    (v) => /^(\d{6})+[-]+(\d{7})/.test(v) || '주민,법인등록번호 형식이 맞지 않습니다',
  ],
  ownerTel: [
    (v) => !!v || '대표자 전화번호를 입력해주세요',
    (v) => /^\d{2,3}-\d{3,4}-\d{4}$/.test(v) || '대표자 전화번호 형식이 맞지 않습니다',
  ],
  companyAddress: [
    (v) => !!v || '사업장소재지 주소를 입력해주세요',
  ],
  registrationAsset: [
    (v) => !!v || '사업자등록증을 첨부해주세요',
  ],
  shopName: [
    (v) => !!v || '상점이름을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|0-9|a-z|A-Z|\s|]+$/.test(v) || '상점이름은 한글,영문,숫자만 입력 가능합니다',
    (v) => (v.length <= 50) || '최대 50글자까지 입력 가능합니다',
  ],
  shopCategory: [
    (v) => !!v || '상점 카테고리를 선택해주세요',
  ],
  shopAddress: [
    (v) => !!v || '상점 주소를 입력해주세요',
  ],
  shopAsset: [
    (v) => !!v || '상점 이미지를 첨부해주세요',
  ],

  /// [PRODUCT]

  /// [MENU]
  menuGroupName: [
    (v) => !!v || '메뉴 그룹명을 입력해주세요',
    (v) => (v.length <= 25) || '최대 25글자까지 입력 가능합니다',
  ],
  menuGroupDescription: [
    (v) => (v.length <= 500) || '최대 500글자까지 입력 가능합니다',
  ],
  menuName: [
    (v) => !!v || '메뉴명을 입력해주세요.',
    (v) => (v.length <= 30) || '최대 30글자까지 입력 가능합니다',
  ],
  priceName: [
    (v) => !!v || '가격명을 입력해주세요',
    (v) => (v.length <= 20) || '최대 20글자까지 입력 가능합니다',
  ],
  price: [
    (v) => !!v || '가격을 입력해주세요',
    (v) => !isNaN(v) || '숫자만 입력 가능합니다.',
    (v) => (parseInt(v) >= 1000) || '입력 가격이 1000원 미만입니다',
  ],
  menuCombination: [
    (v) => (v.length <= 250) || '최대 250글자까지 입력 가능합니다',
  ],
  menuDescription: [
    (v) => (v.length <= 500) || '최대 500글자까지 입력 가능합니다',
  ],
  menuImage: [
    (v) => !!v || '메뉴 이미지를 추가해주세요',
  ],

  /// [OPTION]
  optionGroupName: [
    (v) => !!v || '옵션그룹명을 입력해주세요.',
    (v) => /^[ㄱ-ㅎ|가-힣|0-9|a-z|A-Z|\s|]+$/.test(v) || '옵션 그룹명은 한글,영문,숫자만 입력 가능합니다',
    (v) => (v.length <= 30) || '최대 30글자까지 입력 가능합니다',
  ],
  optionName: [
    (v) => !!v || '옵션명을 입력해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|0-9|a-z|A-Z|\s|]+$/.test(v) || '옵션명은 한글,영문,숫자만 입력 가능합니다',
    (v) => (v.length <= 20) || '최대 20글자까지 입력 가능합니다',
  ],
  minimumOptionCount: [
    (v) => !!v || '최소 옵션 수를 선택해주세요',
  ],
  maximumOptionCount: [
    (v) => !!v || '최대 옵션 수를 선택해주세요',
  ],

  /// [ADDRESS DIALOG]
  searchAddress: [
    (v) => !!v || '검색할 주소를 입력해주세요.',
  ],

  /// [SHOP]

  /// [SHOP INFO]
  logoAsset: [
    (v) => !!v || '로고 이미지를 추가해주세요',
  ],
  logoMessage: [
    (v) => !!v || '어드민 확인 내용을 입력해주세요',
    (v) => (v.length <= 500) || '최대 500자까지 입력가능합니다',
  ],
  trademarkAsset: [
    (v) => !!v || '상표를 추가해주세요',
  ],
  shopTel: [
    (v) => !!v || '전화번호를 입력해주세요',
    (v) => /^\d{2,3}-\d{3,4}-\d{4}$/.test(v) || '올바른 전화번호가 아닙니다'
  ],
  boardSubject: [
    (v) => !!v || '주제를 선택해주세요',
  ],
  boardFile: [
    (v) => !!v || '이미지를 추가해주세요',
  ],
  boardDescription: [
    (v) => (v.length <= 50) || '최대 50자까지 입력가능합니다',
  ],
  boardIsAgreement: [
    (v) => !!v || '동의를 반드시 해주세요',
  ],
  shopDescription: [
    (v) => !!v || '업체 소개를 작성해주세요',
    (v) => /^[ㄱ-ㅎ|가-힣|0-9|a-z|A-Z|\n|!@#$_\-+*&#%^()=,.?<>\s|]+$/.test(v) || '업태명은 한글,영문,숫자만 입력 가능합니다',
    (v) => (v.length <= 500) || '최대 500자까지 입력가능합니다',
  ],
  boardLink: [
    (v) => !!v || '영상 링크를 입력해주세요',
    (v) => (/^(https?):\/\/[^\s$.?#].[^\s]*$/gm.test(v)) || '올바른 영상 링크를 입력해주세요'
  ],
  review: [
    (v) => !!v || '리뷰 검색해주세요',
    (v) => (v.length < 1) || '검색하신 리뷰가 없습니다',
  ],

  /// [MODOO MONEY]
  paymentMethod: [
    (v) => !!v || '결제수단을 선택해주세요',
  ],
  totalChargeMoney: [
    (v) => !!v || '충전 금액을 입력해주세요',
    (v) => !isNaN(v) || '충전 금액은 숫자만 입력 가능합니다.',
  ],

  /// [IT SUPER CALL]
  isItSuperCallAllAgreed: [
    (v) => !!v || '동의는 필수입니다',
  ]
}
