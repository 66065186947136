export default [
  {
    name: '(주)아크',
    nameEn: 'ⓒ ARC Corp',
    ownerName : '박현수',
    registrationNumber: '266-86-01845',
    address: '전라북도 전주시 완산구 홍산중앙로 47, 602호',
    email: 'ceo@arccf.com',
    tel: '070-8725-7777',
    serviceName: '모두CEO',
    // logoImageUrl: {
    //   compony : '',
    //   service_light : '',
    //   service_dark : 'modooceo_logo_dashboard',
    // },
  },
]