<template>
  <v-text-field
    outlined
    :value="value"
    @input="onInput"
    :rules="rules"
    :placeholder="placeholder"
    :required="required"
    :error-messages="error ? error : errorMessage"
    :messages="message"
    :hide-details="hideDetails"
    :disabled="disabled"
    :type="type"
    v-mask="mask"
    :height="height"
    dense
    flat
    :autofocus="autofocus"
    :readonly="readonly"
  >
    <template v-slot:append>
      <slot></slot>
    </template>
    <template v-slot:prepend-inner>
      <slot name="prepend-inner"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  mounted() {
    const slot = document.querySelector('.v-text-field .v-input__control .v-input__slot')
    slot.style.minHeight = this.height + 'px'
  },
  data() {
    return {
      error: '',
    }
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    mask: {
      type: Array,
      required: false,
      default: null,
    },
    value: {
      required: true,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    hideDetails: {
      required: false,
      default: 'auto',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
      default: 48,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    onInput(text) {
      this.error = ''
      this.rules.map((rule) => {
        if (rule(text) != true) {
          this.error = rule(text)
          return
        }
      })
      this.$emit('input', text)
    },
  },
}
</script>

<style lang="scss" scoped>
//.v-text-field::v-deep .v-input__control .v-input__slot {
//  min-height: 48px !important;
//}
.v-text-field--outlined::v-deep fieldset {
  border-color: #ecf0f3 !important;
}
// auto complete 색상
.v-text-field--solo-inverted::v-deep .v-input__control > .v-input__slot input {
  color: #212121 !important;
}
.v-text-field::v-deep .v-input__append-inner {
  margin-top: 0 !important;
  margin: auto 0 !important;
}
</style>
